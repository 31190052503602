<template>
<label class="v-checkbox">

  <div class="v-checkbox__input-wrapper"
    :style="style">

    <input class="v-checkbox__input"
      v-model="model"
      v-bind="{ id, type, name, value, disabled }"
      @change="event => $emit('change', event.target.checked)">

    <svg class="v-checkbox__input-svg"
      viewBox="0 0 50 50">

      <component :is="shape"
        class="v-checkbox__input-svg-border"
        cx="50%" cy="50%"
        rx="8%" ry="8%" r="50%">
      </component>

      <component :is="shape"
        class="v-checkbox__input-svg-border v-checkbox__input-svg-border--stroked"
        cx="50%" cy="50%"
        rx="8%" ry="8%" r="50%">
      </component>

      <component :is="shape"
        class="v-checkbox__input-svg-mark v-checkbox__input-svg-mark--filled"
        v-if="isFilled && mark!='circle'"
        cx="50%" cy="50%"
        rx="8%" ry="8%" r="45%">
      </component>

      <circle
        class="v-checkbox__input-svg-mark v-checkbox__input-svg-mark--circle"
        v-if="mark=='circle'"
        cx="50%" cy="50%" r="40%">
      </circle>

      <line class="v-checkbox__input-svg-mark v-checkbox__input-svg-mark--stroked"
        v-if="mark=='cross'"
        x1="0%" y1="50%" x2="100%" y2="50%"
        transform="rotate(45)">
      </line>
      <line class="v-checkbox__input-svg-mark v-checkbox__input-svg-mark--stroked v-checkbox__input-svg-mark--delayed"
        v-if="mark=='cross'"
        x1="0%" y1="50%" x2="100%" y2="50%"
        transform="rotate(135)">
      </line>

      <path class="v-checkbox__input-svg-mark v-checkbox__input-svg-mark--stroked"
        v-if="mark=='tick'"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
        :style="styleMark">
      </path>

    </svg>

  </div>

  <div v-if="label || info">
    <div class="v-checkbox__label"
      v-if="label">
      <i class="material-symbols"
        v-if="icon">
        {{ icon }}
      </i>
      {{ label }}
    </div>
    <div class="v-checkbox__info" 
      v-if="info"
      v-html="info">
    </div>
  </div>

  <slot/>

</label>
</template>

<script>
export default {
  emits: [
    'update:modelValue',
    'change',
  ],
  props: {
    isFilled: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, required: false, default: false },
    //checked: { type: Boolean, required: false, default: undefined },
    value: { type: [Boolean, Number, String], required: false, default: undefined },
    modelValue: { type: [Boolean, Number, String, Array], required: false, default: undefined },
    id: { type: String, required: false, default: undefined },
    name: { type: String, required: false, default: "myCheckbox" },
    label: { type: String, required: false, default: undefined },
    info: { type: String, required: false, default: undefined },
    icon: { type: String, required: false, default: undefined },
    type: { type: String, required: false, default: "checkbox", validator: val => ["checkbox", "radio"].includes(val) },
    shape: { type: String, required: false, default: "rect", validator: val => ["rect", "circle"].includes(val) },
    mark: { type: String, required: false, default: "tick", validator: val => ["tick", "cross", "circle", 'none'].includes(val) },
    style: { type: [Object, String], required: false, default: '' },
    colorActive: { type: String, required: false, default: "#433298" },
    colorBorder: { type: String, required: false, default: "#aaa" },
    markOpacity: { type: String, required: false, default: "1" },
    borderWidth: { type: [Number, String], required: false, default: '4%' },
    strokeWidth: { type: [Number, String], required: false, default: '9%' },
  },
  computed: {
    model: {
      get() { return this.modelValue },
      set(value) {
        this.$emit("update:modelValue", value)
      }
    },
    styleMark() { return {
      stroke: this.isFilled ? '#ffffff' : this.colorActive
    }}
  }
}
</script>

<style lang="scss" scoped>
.v-checkbox {
  display: flex;
  align-items: center;
  text-align: left;
  gap: .75em;
  &__input-wrapper {
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    width: 1.25em;
    height: 1.25em;
  }
  &__input {
    appearance: none;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    &:not(:disabled):hover {
      cursor: pointer;
    }
    &:disabled + svg {
      opacity: .75;
    }
    &:checked + svg &-svg-border--stroked,
    &:checked + svg &-svg-mark--stroked {
      stroke-dashoffset: 0;
    }
    &:checked + svg &-svg-mark--filled {
      transform: scale(1);
    }
    &:checked + svg &-svg-mark--circle {
      transform: scale(.85);
    }
  }
  &__input-svg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  &__input-svg-border {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: v-bind(borderWidth);
    stroke: v-bind(colorBorder);
    &--stroked {
      fill: none;
      stroke: v-bind(colorActive);
      stroke-width: v-bind(strokeWidth);
      stroke-linecap: round;
      stroke-dasharray: 400% 400%;
      stroke-dashoffset: 400%;
      transition: stroke-dashoffset 400ms linear;
    }
  }
  &__input-svg-mark {
    width: 100%;
    height: 100%;
    opacity: v-bind(markOpacity);
    &--stroked {
      fill: none;
      stroke: v-bind(colorActive);
      stroke-width: v-bind(strokeWidth);
      stroke-linecap: round;
      stroke-dasharray: 100% 100%;
      stroke-dashoffset: 100%;
      transform-origin: center;
      transition: stroke-dashoffset 200ms linear;
    }
    &--filled, &--circle {
      fill: v-bind(colorActive);
      stroke: none;
      transform: scale(0);
      transform-origin: center;
      transition: transform 200ms linear;
    }
    &--delayed {
      transition-delay: 100ms;
    }
  }
  &__label {
    display: flex;
    align-items: center;
    gap: .5em;
    color: #333;
    line-height: 1.5;
    i { font-size: 1.35em; }
  }
  &__info {
    margin-top: .25em;
    font-size: .9em;
    line-height: 1.5;
    color: #0008;
  }
}
</style>