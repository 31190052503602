<template>
<div class="v-tooltip"
  ref="root"
  @mouseenter="showTooltip"
  @mouseleave="hideTooltip">

  <slot></slot>

  <Teleport to="body">
    <div class="v-tooltip__tooltip"
      ref="tooltip"
      v-if="visible">
      {{ text }}
    </div>
  </Teleport>

</div>
</template>

<script>
export default {
  props: {
    text: { type: String, default: true },
    width: { type: String, default: '20em' },
    offsetX: { type: Number, default: 0 },
    offsetY: { type: Number, default: 10 },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    showTooltip() {
      this.visible = true
      this.$nextTick(this.updateTooltipPosition)
    },
    hideTooltip() {
      this.visible = false
    },
    updateTooltipPosition() {
      const triggerRect = this.$refs.root.getBoundingClientRect()
      const tooltip = this.$refs.tooltip
      Object.assign(tooltip.style, {
        top: `${triggerRect.top + window.scrollY - this.offsetY}px`,
        left: `${triggerRect.right + window.scrollX - this.offsetX}px`,
        transform: 'translate(-100%, -100%)', 
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-tooltip {
  display: inline-block;
  position: relative;
  &__tooltip {
    position: absolute;
    max-width: v-bind(width);
    background-color: #0008;
    color: white;
    padding: 8px;
    border-radius: .5em;
    white-space: nowrap;
    z-index: 1000;
    font-size: 14px;
    transition: opacity 0.2s;
    white-space: normal;
    line-height: 1.5;
  }
}
</style>