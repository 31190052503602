import axios from 'axios'
import store from '@/store'
import router from '@/router'
import popups from '@/plugins/popups/popups'

const timeout = 60000

axios.defaults.withCredentials = true

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
  timeout,
  headers: {
    'Content-Type': 'application/json',
  }
})

let loaderTimeout

instance.interceptors.request.use(

  config => {
    if (!loaderTimeout) 
    loaderTimeout = setTimeout(() => store.commit('showLoader', true), 1000)
    const access = localStorage.getItem('accessToken')
    if (access) config.headers.auth = `Bearer ${access}`
    if (store.state.subjectId) config.headers.subject = store.state.subjectId
    return config 
  }, 

  error => {
    clearTimeout(loaderTimeout)
    store.commit('showLoader', false)
    return Promise.reject(error)
  }
  
)

instance.interceptors.response.use(

  response => {
    clearTimeout(loaderTimeout)
    store.commit('showLoader', false)
    if (response.status == 202) popups.popupSuccess(
      response.data?.message,
      "Успішно",
      "verified"
    )
    if (response.data?.notifications && store.state.role != 'superadmin') {
      response.data.notifications.filter(e => !e.isSeen && e.isPopup).forEach((notification, i) => {
        console.log(store.state.course)
        setTimeout(_ => popups.popupNotification({
          subject: store.state.subject,
          course: store.state.course,
          notification,
        }), 500 + 500*i)
      })
    }
    return response
  },

  async error => {

    clearTimeout(loaderTimeout)
    store.commit('showLoader', false)

    if (error.response?.status == 403) router.push('/confirm')

    else if (error.response?.status == 401 && error.config) {
      try {
        const { data: { accessToken } } = await axios.get(import.meta.env.VITE_API_URL + '/refresh', { withCredentials: true })
        localStorage.setItem('accessToken', accessToken)
        return instance.request(error.config)
      }
      catch (err) {
        localStorage.removeItem('accessToken')
        router.push({ name: 'route-signin' })
      } 
      throw error
    }

    else {
      const msg = error.response?.data?.message
      const msgSuffix = `
        <div style='margin-top:1em'>
          <a class='link' href="javascript:window.history.back()">Назад</a>
          &ensp;&bull;&ensp;
          <a class='link' href="javascript:location.reload()">Оновити</a>
          &ensp;&bull;&ensp;
          <a class='link' href="/">На головну</a>
        </div>`
      popups.popupError(
        !msg ? `Перевищено час очікування. ${msgSuffix}` : msg + msgSuffix, 
        error.response?.data?.header || "Помилка",
        error.response?.data?.icon || 'hourglass_empty', 
      )
    }

    return Promise.reject({ ...error })

  }

)

export default instance